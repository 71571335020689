<div class="w-full flex justify-end my-8">
  <button
    (click)="openClimateTemplateDialog()"
    color="primary"
    mat-stroked-button
  >
    <mat-icon>add</mat-icon>
    Erfassung anlegen
  </button>
</div>
<table
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="announceSortChange($event)"
  class="mat-elevation-z8"
>
  <ng-container matColumnDef="sToken">
    <th mat-header-cell *matHeaderCellDef>Token</th>
    <td mat-cell *matCellDef="let element">
      @if (!element.bFilledByUser) {
        <div>
          <a
            [href]="baseUrl + '/climateEntry/' + element.sToken"
            target="_blank"
            class="text-accent"
            >{{ element.sToken }}</a
          >
        </div>
      } @else {
        {{ element.sToken }}
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="sTitle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Veranstaltung</th>
    <td mat-cell *matCellDef="let element">{{ element.sTitle }}</td>
  </ng-container>

  <ng-container matColumnDef="dtStart">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Beginn</th>
    <td mat-cell *matCellDef="let element">{{ element.dtStart | date }}</td>
  </ng-container>

  <ng-container matColumnDef="dtEnd">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ende</th>
    <td mat-cell *matCellDef="let element">{{ element.dtEnd | date }}</td>
  </ng-container>

  <ng-container matColumnDef="iPersons">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Personen</th>
    <td mat-cell *matCellDef="let element">{{ element.iPersons }}</td>
  </ng-container>

  <ng-container matColumnDef="co2Consumption">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Co2 Verbrauch</th>
    <td mat-cell *matCellDef="let element">
      {{ element | climateCalculation | async | number }} kg
    </td>
  </ng-container>
  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>Details</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="openDetailsDialog(element)">
        <mat-icon>info</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="multiplier">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Multiplikator</th>
    <td mat-cell *matCellDef="let element">
      {{ element.multiplier }}
      <button mat-icon-button (click)="openEditMultiplierDialog(element)">
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef>Löschen</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="openDeleteDialog(element)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
