import { ValidationErrors } from '@angular/forms';
import { produce } from 'immer';

export interface NgxsForm<T> {
  model: T;
  dirty: boolean;
  status: 'VALID' | 'INVALID' | 'PENDING' | '';
  errors: ValidationErrors | null;
}

export const createDefaultForm: <T>(model: T) => NgxsForm<T> = (model) => ({
  model,
  dirty: false,
  status: '',
  errors: null,
});

export const immutableContext: <T>(
  cb: (state: Writable<T>) => void
) => (state: T) => T = (cb) => (state) => {
  return produce(state, (draft: any) => {
    cb(draft);
    return draft;
  });
};

type Writable<T> = {
  -readonly [K in keyof T]: T[K];
};
