import { Injectable } from '@angular/core';
import { ClimateEntry } from '../../core/swagger/models/climate-entry';
import { Transportation } from '../../core/swagger/models/transportation';
import { ClimateCalculationKeys } from '../../core/swagger/models/climate-calculation-keys';
import { Accommodation } from '../../core/swagger/models/accommodation';
import { BuildingDto } from '../../core/swagger/models/building-dto';

@Injectable({
  providedIn: 'root',
})
export class ClimateCalculationService {
  public static calculateClimateEntry(
    climateEntry: ClimateEntry,
    climateCalculationKeys: ClimateCalculationKeys,
  ): number {
    let co2Consumption: number = 0;
    co2Consumption += this.calculateTransportations(
      climateEntry.transportations!,
      climateCalculationKeys,
    );
    co2Consumption += this.calculateAccommodations(
      climateEntry.accommodations!,
      climateCalculationKeys,
    );
    co2Consumption = co2Consumption * (climateEntry.multiplier ?? 1);
    return co2Consumption;
  }

  public static calculateTransportations(
    transportations: Array<Transportation>,
    climateCalculationKeys: ClimateCalculationKeys,
  ): number {
    let co2Consumption: number = 0;
    transportations.forEach((transportation) => {
      switch (transportation.eType) {
        case 'BUS':
          co2Consumption +=
            climateCalculationKeys!.mobility!.bus! *
            transportation.iDistance! *
            transportation.iNumber!;
          break;
        case 'VAN':
          co2Consumption +=
            climateCalculationKeys!.mobility!.van! * transportation.iDistance!;
          break;
        case 'DAV_BUS':
          co2Consumption +=
            climateCalculationKeys!.mobility!.van! *
            transportation.iDistance! *
            transportation.iNumber!;
          break;
        case 'NEAR_PUBLIC':
          co2Consumption +=
            climateCalculationKeys!.mobility!.nearPublic! *
            transportation.iDistance! *
            transportation.iNumber!;
          break;
        case 'LONG_PUBLIC':
          co2Consumption +=
            climateCalculationKeys!.mobility!.longPublic! *
            transportation.iDistance! *
            transportation.iNumber!;
          break;
        case 'PKW_Benzin':
        case 'PKW_DIESEL':
        case 'PKW_PLUGIN':
          co2Consumption +=
            climateCalculationKeys!.mobility!.pkwBenzin! *
            transportation.iDistance! *
            transportation.iNumber!;
          break;
        case 'PKW_ELECTRO':
          co2Consumption +=
            climateCalculationKeys!.mobility!.pkwElectro! *
            transportation.iDistance! *
            transportation.iNumber!;
          break;
        case 'AIRPLANE':
          co2Consumption +=
            climateCalculationKeys!.mobility!.airplane! *
            transportation.iNumber! *
            transportation.iDistance!;
      }
    });
    return co2Consumption;
  }

  public static calculateAccommodations(
    accommodations: Array<Accommodation>,
    climateCalculationKeys: ClimateCalculationKeys,
  ): number {
    let co2Consumption: number = 0;
    accommodations.forEach((accommodation) => {
      switch (accommodation.eType) {
        case 'CAMPING':
          co2Consumption +=
            climateCalculationKeys.accommodations!.camping! *
            accommodation.iNights! *
            accommodation.iPersons!;
          break;
        case 'BIWAK':
          co2Consumption +=
            climateCalculationKeys.accommodations!.biwak! *
            accommodation.iNights! *
            accommodation.iPersons!;
          break;
        case 'HOSTEL':
          co2Consumption +=
            climateCalculationKeys.accommodations!.hostel! *
            accommodation.iNights! *
            accommodation.iPersons!;
          break;
        case 'HOTEL':
          co2Consumption +=
            climateCalculationKeys.accommodations!.hotel! *
            accommodation.iNights! *
            accommodation.iPersons!;
          break;
        case 'HOTEL_LUXURY':
          co2Consumption +=
            climateCalculationKeys.accommodations!.hotelLuxury! *
            accommodation.iNights! *
            accommodation.iPersons!;
          break;
      }
    });
    return co2Consumption;
  }

  public static calculateBuildings(
    buildings: Array<BuildingDto>,
    climateCalculationKeys: ClimateCalculationKeys,
  ): number {
    let co2Consumption: number = 0;
    buildings.forEach((building) => {
      co2Consumption +=
        building.electricityKWH! *
        climateCalculationKeys.buildings.electricityKWH!;
      co2Consumption +=
        building.gasKWH! * climateCalculationKeys.buildings.gasKWH!;
      co2Consumption +=
        building.wasteKg! * climateCalculationKeys.buildings.wasteKg!;
      co2Consumption +=
        building.waterL! * climateCalculationKeys.buildings.waterL!;
      co2Consumption +=
        building.foodKg! * climateCalculationKeys.buildings.foodKg!;
      co2Consumption +=
        building.beveragesL! * climateCalculationKeys.buildings.beveragesL!;
      co2Consumption += building.miscellaneous!;
    });
    return co2Consumption;
  }

  public static calculateCatering(
    climateEntry: ClimateEntry,
    climateCalculationKeys: ClimateCalculationKeys,
  ): number {
    let co2Consumption: number = 0;
    if (climateEntry.iVegetarians) {
      co2Consumption +=
        climateEntry.iVegetarians *
        climateCalculationKeys.caterings.vegetarianMeal!;
    }
    if (climateEntry.iPersonsWithMeat) {
      co2Consumption +=
        climateEntry.iPersonsWithMeat *
        climateCalculationKeys.caterings.meatMeal!;
    }
    return co2Consumption;
  }

  public static calculateBalance(
    climateEntries: Array<ClimateEntry>,
    buildings: Array<BuildingDto>,
    climateCalculationKeys: ClimateCalculationKeys,
  ): CO2Balance {
    const cO2Balance: CO2Balance = {
      total: 0,
      transportations: 0,
      accommodations: 0,
      catering: 0,
      facilities: 0,
    };
    climateEntries.forEach((climateEntry) => {
      cO2Balance.accommodations += this.calculateAccommodations(
        climateEntry.accommodations,
        climateCalculationKeys,
      );
      cO2Balance.transportations += this.calculateTransportations(
        climateEntry.transportations,
        climateCalculationKeys,
      );
      cO2Balance.catering += this.calculateCatering(
        climateEntry,
        climateCalculationKeys,
      );
    });
    cO2Balance.facilities = this.calculateBuildings(
      buildings,
      climateCalculationKeys,
    );
    cO2Balance.total =
      cO2Balance.facilities +
      cO2Balance.transportations +
      cO2Balance.catering +
      cO2Balance.accommodations;
    console.log(cO2Balance);
    return cO2Balance;
  }
}

export interface CO2Balance {
  total: number;
  transportations: number;
  accommodations: number;
  catering: number;
  facilities: number;
}
