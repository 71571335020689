import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ClimateEntry } from '../../../../core/swagger/models/climate-entry';
import { MatButton } from '@angular/material/button';
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-update-multiplier',
  standalone: true,
  imports: [
    MatButton,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    FormsModule,
    MatInput,
    MatDialogClose,
    MatDialogActions,
    MatLabel,
  ],
  templateUrl: './update-multiplier.component.html',
})
export class UpdateMultiplierComponent {
  public multiplier?: number = this.climateEntry.multiplier;

  constructor(
    public dialogRef: MatDialogRef<UpdateMultiplierComponent>,
    @Inject(MAT_DIALOG_DATA) public climateEntry: ClimateEntry,
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
