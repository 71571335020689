<h1 mat-dialog-title>Erfassung anlegen</h1>
<div *transloco="let t" class="flex flex-wrap max-w-l" mat-dialog-content>
  <mat-form-field class="w-full">
    <mat-label>Titel</mat-label>
    <input matInput [(ngModel)]="data.sTitle" />
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label></mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input [(ngModel)]="data.dtStart" matStartDate placeholder="Beginn" />
      <input [(ngModel)]="data.dtEnd" matEndDate placeholder="Ende" />
    </mat-date-range-input>
    <mat-hint>TT.MM.JJJJ – TT.MM.JJJJ</mat-hint>
    <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <mat-form-field class="w-1/2">
    <mat-label>Abteilung</mat-label>
    <mat-select [(ngModel)]="data.eDepartment">
      @for (departmentType of departmentTypes; track departmentType) {
        <mat-option [value]="departmentType">
          {{ t("departmentTypes." + departmentType) }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-1/2">
    <mat-label>Sportart</mat-label>
    <mat-select [(ngModel)]="data.eSport">
      @for (sportType of sportTypes; track sportType) {
        <mat-option [value]="sportType">
          {{ t("sportsTypes." + sportType) }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>E-Mail Trainer (optional)</mat-label>
    <input matInput [(ngModel)]="data.trainerEmail" />
  </mat-form-field>
</div>
<div class="w-full flex justify-between" mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <div class="grow"></div>
  <button
    mat-button
    color="primary"
    mat-stroked-button
    [mat-dialog-close]="data"
  >
    Speichern
  </button>
</div>
