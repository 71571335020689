import { Component, inject } from '@angular/core';
import { ClimateEntriesQueries } from '../../../states/climateEntries/climate-entries.queries';
import { AsyncPipe, DecimalPipe, JsonPipe } from '@angular/common';
import {
  BarChartComponent,
  BarData,
} from '../../../shared/components/bar-chart/bar-chart.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  standalone: true,
  imports: [AsyncPipe, JsonPipe, BarChartComponent, DecimalPipe],
})
export class BalanceComponent {
  public readonly climateEntriesQueries: ClimateEntriesQueries = inject(
    ClimateEntriesQueries,
  );
  public readonly barChartData$: Observable<Array<BarData>> =
    this.climateEntriesQueries.climateBalance.pipe(
      map((balance) => [
        { label: 'Unterkünfte', value: balance.accommodations / 1000 },
        { label: 'Mobilität', value: balance.transportations / 1000 },
        { label: 'Verpflegung', value: balance.catering / 1000 },
        { label: 'Gebäude', value: balance.facilities / 1000 },
      ]),
    );
}
