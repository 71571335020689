import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { Store } from '@ngxs/store';
import { MatTabsModule } from '@angular/material/tabs';
import { ClimateCalculationPipe } from '../../../shared/pipes/climate-calculation.pipe';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../core/swagger/services/api.service';
import { ClimateEntriesQueries } from '../../../states/climateEntries/climate-entries.queries';
import { CreateClimateTemplateComponent } from './create-climate-template/create-climate-template.component';
import {
  AddClimateEntry,
  RemoveClimateEntry,
  UpdateMultiplier,
} from '../../../states/climateEntries/climate-entries.actions';
import { DeleteClimateEntryComponent } from './delete-climate-entry/delete-climate-entry.component';
import { ClimateEntry } from '../../../core/swagger/models/climate-entry';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DetailsClimateEntryComponent } from './details-climate-entry/details-climate-entry.component';
import { UpdateMultiplierComponent } from './update-multiplier/update-multiplier.component';
import { DateService } from '../../../shared/services/date.service';

@Component({
  selector: 'app-captures',
  templateUrl: './captures.component.html',
  standalone: true,
  imports: [
    MatTableModule,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClimateCalculationPipe,
    CommonModule,
    MatTabsModule,
    MatSortModule,
  ],
})
export class CapturesComponent implements OnInit, AfterViewInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly matSnackBar: MatSnackBar = inject(MatSnackBar);
  private readonly dateService: DateService = inject(DateService);
  public displayedColumns: Array<string> = [
    'sToken',
    'sTitle',
    'dtStart',
    'dtEnd',
    'iPersons',
    'co2Consumption',
    'details',
    'multiplier',
    'delete',
  ];
  public dataSource: MatTableDataSource<ClimateEntry> =
    new MatTableDataSource<ClimateEntry>([]);
  public readonly baseUrl: string = environment.baseUrl;
  @ViewChild(MatSort) public sort: MatSort | undefined;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly service: ApiService,
    private readonly store: Store,
    private readonly climateEntriesQueries: ClimateEntriesQueries,
    private readonly _liveAnnouncer: LiveAnnouncer,
  ) {}

  public ngOnInit(): void {
    this.climateEntriesQueries.climateEntries$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((climateEntries) => (this.dataSource.data = climateEntries));
  }

  public ngAfterViewInit(): void {
    if (this.sort) this.dataSource.sort = this.sort;
  }

  public openClimateTemplateDialog(): void {
    const dialogRef: MatDialogRef<CreateClimateTemplateComponent> =
      this.matDialog.open(CreateClimateTemplateComponent, {
        data: { sTitle: '' },
      });

    dialogRef.afterClosed().subscribe((climateTemplate) => {
      const dtStart: Date = new Date(climateTemplate.dtStart);
      dtStart.setHours(dtStart.getHours() + 4);
      const dtEnd: Date = new Date(climateTemplate.dtEnd);
      dtEnd.setHours(dtEnd.getHours() + 4);
      climateTemplate.dtStart = this.dateService.formatDateDTO(dtStart);
      climateTemplate.dtEnd = this.dateService.formatDateDTO(dtEnd);
      this.service
        .adminClimateTemplatePost({ body: climateTemplate })
        .subscribe((climateEntry) => {
          this.store.dispatch(new AddClimateEntry(climateEntry));
        });
    });
  }

  public openDetailsDialog(climateEntry: ClimateEntry): void {
    this.matDialog.open(DetailsClimateEntryComponent, {
      data: climateEntry,
    });
  }

  public openDeleteDialog(climateEntry: ClimateEntry): void {
    const dialogRef: MatDialogRef<DeleteClimateEntryComponent> =
      this.matDialog.open(DeleteClimateEntryComponent, {
        data: climateEntry,
      });

    dialogRef.afterClosed().subscribe((_delete) => {
      if (_delete) {
        this.service
          .adminClimateEntrySTokenDelete({ sToken: climateEntry.sToken })
          .subscribe({
            next: () =>
              this.matSnackBar.open(
                'Der Eintrag wurde erfolgreich gelöscht',
                'Ok',
              ),
            error: () =>
              this.matSnackBar.open(
                'Der Eintrag konnte leider nicht gelöscht werden',
                'Ok',
              ),
          });
        this.store.dispatch(new RemoveClimateEntry(climateEntry.sToken));
      }
    });
  }

  public openEditMultiplierDialog(climateEntry: ClimateEntry): void {
    const dialogRef: MatDialogRef<UpdateMultiplierComponent> =
      this.matDialog.open(UpdateMultiplierComponent, {
        data: climateEntry,
      });

    dialogRef.afterClosed().subscribe((multiplier) => {
      this.service
        .adminClimateEntrySTokenMultiplierPatch({
          sToken: climateEntry.sToken,
          multiplier: multiplier,
        })
        .subscribe({
          next: () => {
            this.matSnackBar.open(
              'Der Multiplikator wurde erfolgreich geländert',
              'Ok',
            );
            this.store.dispatch(
              new UpdateMultiplier(climateEntry.sToken, multiplier),
            );
          },
          error: (err) => {
            console.log(err);
            this.matSnackBar.open(
              'Der Multiplikator konnte leider nicht geändert werden',
              'Ok',
            );
          },
        });
    });
  }

  public announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
