<h1 mat-dialog-title>Wirklich löschen?</h1>
<div class="flex flex-wrap max-w-l" mat-dialog-content>
  Soll
  <div class="font-bold text-red-700">
    {{ data.sTitle }} ({{ data.sToken }})
  </div>
  wirklich gelöscht werden?
</div>
<div class="w-full flex justify-between" mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <div class="grow"></div>
  <button mat-button color="warn" mat-stroked-button [mat-dialog-close]="true">
    Löschen
  </button>
</div>
