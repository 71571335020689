import { ClimateEntry } from '../../core/swagger/models/climate-entry';
import { ClimateCalculationKeys } from '../../core/swagger/models/climate-calculation-keys';
import { BuildingDto } from '../../core/swagger/models/building-dto';

export interface ClimateEntriesStateModel {
  activeYear: number;
  climateEntries: Array<ClimateEntry>;
  buildings: Array<BuildingDto>;
  climateCalculationKeys?: ClimateCalculationKeys;
}

export const initializeClimateEntryStateModel: () => ClimateEntriesStateModel =
  () => ({
    activeYear: new Date().getFullYear(),
    climateEntries: [],
    buildings: [],
    climateCalculationKeys: undefined,
  });
