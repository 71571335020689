<h1 mat-dialog-title>Multiplikator ändern</h1>
<mat-form-field class="w-full">
  <mat-label>Multiplikator</mat-label>
  <input matInput [(ngModel)]="multiplier" />
</mat-form-field>
<div class="w-full flex justify-between" mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <div class="grow"></div>
  <button
    mat-button
    color="primary"
    mat-stroked-button
    [mat-dialog-close]="multiplier"
  >
    Speichern
  </button>
</div>
