import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { DepartmentType, SportType } from '../../../../models/ClimateEntry';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoDirective } from '@ngneat/transloco';
import { MatCheckbox } from '@angular/material/checkbox';
import { ClimateTemplatePost } from '../../../../core/swagger/models/climate-template-post';

@Component({
  selector: 'app-create-climate-template',
  templateUrl: './create-climate-template.component.html',
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    TranslocoDirective,
    MatCheckbox,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' }],
})
export class CreateClimateTemplateComponent {
  public departmentTypes: Array<string> = Object.values(DepartmentType);
  public sportTypes: Array<string> = Object.values(SportType);

  constructor(
    public dialogRef: MatDialogRef<CreateClimateTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ClimateTemplatePost,
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
