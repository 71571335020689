
import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as d3 from 'd3';
import { FormatLocaleObject, ScaleBand, ScaleLinear } from 'd3';

interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [],
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BarChartComponent {
  @Input({ required: true })
  public set barData(barData: Array<BarData> | undefined) {
    if (barData) {
      this.createBarChart(barData.sort((a, b) => b.value - a.value));
    }
  }

  @ViewChild('chart', { static: true })
  private readonly chartContainer!: ElementRef;

  private createBarChart(barData: Array<BarData>): void {
    const EU: FormatLocaleObject = d3.formatLocale({
      decimal: ',',
      thousands: ' ',
      grouping: [3],
      currency: ['', 't'],
    });
    const margin: Margin = { top: 45, right: 30, bottom: 45, left: 130 };
    const width: number = 500;
    const height: number = 500;
    const x: ScaleBand<string> = d3.scaleBand().range([0, width]);
    const y: ScaleLinear<number, number> = d3.scaleLinear().range([height, 0]);

    this.chartContainer.nativeElement.innerHTML = '';

    const svg: d3.Selection<SVGGElement, unknown, null, undefined> = d3
      .select(this.chartContainer.nativeElement)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    x.domain(barData.map((d) => d.label));
    let minValue: number = d3.min(barData, (d) => d.value) as number;
    if (minValue > 0) {
      minValue = 0;
    }
    y.domain([minValue, d3.max(barData, (d) => d.value) as number]);

    svg
      .append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).ticks(10));

    svg
      .append('g')
      .attr('class', 'axis')
      .call(d3.axisLeft(y).scale(y).ticks(5).tickFormat(EU.format('$,.0f')));

    svg
      .append('g')
      .attr('class', 'axis grid')
      .call(
        d3
          .axisLeft(y)
          .scale(y)
          .ticks(5)
          .tickSize(-width)
          .tickFormat(() => ''),
      );
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -90)
      .attr('x', 0 - height / 2)
      .attr('dy', '.70em')
      .style('text-anchor', 'middle')
      .style('font-style', 'normal')
      .text('CO2 Verbrauch');
    svg
      .selectAll('bar')
      .data(barData)
      .enter()
      .append('rect')
      .attr('transform', (d) => 'translate(' + (x(d.label)! + 25) + ',0)')
      .style('fill', '#525252')
      .attr('width', width / barData.length - 50)
      .attr('y', (d) => y(Math.max(0, d.value)))
      .attr('height', 0)
      .transition()
      .duration(1000)
      .delay((_d, i) => i)
      .attr('y', (d) => {
        return y(Math.max(0, d.value));
      })
      .attr('height', (d) => Math.abs(y(d.value) - y(0)));
  }
}

export interface BarData {
  label: string;
  value: number;
}
