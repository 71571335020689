import { ClimateEntry } from '../../core/swagger/models/climate-entry';

const ACTION_PREFIX: string = '[ClimateEntries]';

export class FetchBalanceEntries {
  public static readonly type: string = `${ACTION_PREFIX} set BalanceEntries`;
}

export class AddClimateEntry {
  public static readonly type: string = `${ACTION_PREFIX} add ClimateEntry`;

  public constructor(public readonly climateEntry: ClimateEntry) {}
}

export class RemoveClimateEntry {
  public static readonly type: string = `${ACTION_PREFIX} remove ClimateEntry`;

  public constructor(public readonly sToken: string) {}
}

export class UpdateMultiplier {
  public static readonly type: string = `${ACTION_PREFIX} update Multiplier`;

  public constructor(
    public readonly sToken: string,
    public readonly multiplier?: number,
  ) {}
}

export class UpdateActiveYear {
  public static readonly type: string = `${ACTION_PREFIX} update ActiveYear`;

  public constructor(public readonly activeYear: number) {}
}

export class FetchClimateCalculationKeys {
  public static readonly type: string = `${ACTION_PREFIX} set ClimateCalculationKeys`;
}
