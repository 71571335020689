import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ClimateEntry } from '../../../../core/swagger/models/climate-entry';
import { TranslocoDirective } from '@ngneat/transloco';
import { ClimateCalculationTransportationPipe } from '../../../../shared/pipes/climate-calculation-transportation.pipe';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ClimateCalculationAccommodationPipe } from '../../../../shared/pipes/climate-calculation-accommodation.pipe';

@Component({
  selector: 'app-details-climate-entry',
  templateUrl: './details-climate-entry.component.html',
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    TranslocoDirective,
    ClimateCalculationTransportationPipe,
    DecimalPipe,
    AsyncPipe,
    ClimateCalculationAccommodationPipe,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' }],
})
export class DetailsClimateEntryComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public climateEntry: ClimateEntry) {}

  public trackByIndex(index: number): number {
    return index;
  }
}
