import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ClimateEntriesState } from './climate-entries.state';
import { ClimateEntry } from '../../core/swagger/models/climate-entry';
import { ClimateCalculationKeys } from '../../core/swagger/models/climate-calculation-keys';
import { CO2Balance } from '../../shared/services/climate-calculation.service';
import { BuildingDto } from '../../core/swagger/models/building-dto';

@Injectable({ providedIn: 'root' })
export class ClimateEntriesQueries {
  private readonly store: Store = inject(Store);
  public climateEntries$: Observable<Array<ClimateEntry>> = this.store.select(
    ClimateEntriesState.getClimateEntries,
  );
  public buildings$: Observable<Array<BuildingDto>> = this.store.select(
    ClimateEntriesState.getBuildings,
  );
  public climateCalculationKeys$: Observable<
    ClimateCalculationKeys | undefined
  > = this.store.select(ClimateEntriesState.getClimateCalculationKeys);
  public climateBalance: Observable<CO2Balance> = this.store.select(
    ClimateEntriesState.getClimateBalance,
  );
}
