@if (barChartData$ | async; as barChartData) {
  <div class="w-[50rem] h-[40rem]">
    <app-bar-chart [barData]="barChartData"></app-bar-chart>
  </div>
}
@if (climateEntriesQueries.climateBalance | async; as climateBalance) {
  <div class="flex flex-wrap">
    <div class="w-1/2">Transport:</div>
    <div class="w-1/2">
      {{ climateBalance.transportations / 1000 | number: "1.2-2" : "de-DE" }} t
    </div>
    <div class="w-1/2">Unterkünfte:</div>
    <div class="w-1/2">
      {{ climateBalance.accommodations / 1000 | number: "1.2-2" : "de-DE" }} t
    </div>
    <div class="w-1/2">Verpflegung:</div>
    <div class="w-1/2">
      {{ climateBalance.catering / 1000 | number: "1.2-2" : "de-DE" }} t
    </div>
    <div class="w-1/2">Gebäude:</div>
    <div class="w-1/2">
      {{ climateBalance.facilities / 1000 | number: "1.2-2" : "de-DE" }} t
    </div>
    <div class="w-1/2">Gesamt:</div>
    <div class="w-1/2">
      {{ climateBalance.total / 1000 | number: "1.2-2" : "de-DE" }} t
    </div>
  </div>
}
