import { inject, Pipe, PipeTransform } from '@angular/core';
import { ClimateEntriesQueries } from '../../states/climateEntries/climate-entries.queries';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClimateCalculationService } from '../services/climate-calculation.service';
import { Transportation } from '../../core/swagger/models/transportation';

@Pipe({
  name: 'climateCalculationTransportation',
  standalone: true,
})
export class ClimateCalculationTransportationPipe implements PipeTransform {
  private readonly climateCalculationKeysQueries: ClimateEntriesQueries =
    inject(ClimateEntriesQueries);

  public transform(transportation: Transportation): Observable<number> {
    return this.climateCalculationKeysQueries.climateCalculationKeys$.pipe(
      map((climateCalculationKeys) =>
        climateCalculationKeys
          ? ClimateCalculationService.calculateTransportations(
              [transportation],
              climateCalculationKeys,
            )
          : 0,
      ),
    );
  }
}
