import { inject, Pipe, PipeTransform } from '@angular/core';
import { ClimateEntry } from '../../core/swagger/models/climate-entry';
import { ClimateCalculationService } from '../services/climate-calculation.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClimateEntriesQueries } from '../../states/climateEntries/climate-entries.queries';

@Pipe({
  name: 'climateCalculation',
  standalone: true,
})
export class ClimateCalculationPipe implements PipeTransform {
  private readonly climateCalculationKeysQueries: ClimateEntriesQueries =
    inject(ClimateEntriesQueries);

  public transform(climateEntry: ClimateEntry): Observable<number> {
    return this.climateCalculationKeysQueries.climateCalculationKeys$.pipe(
      map((climateCalculationKeys) =>
        climateCalculationKeys
          ? ClimateCalculationService.calculateClimateEntry(
              climateEntry,
              climateCalculationKeys,
            )
          : 0,
      ),
    );
  }
}
