export enum TransportationTyp {
  PKW_DIESEL = 'PKW_DIESEL',
  PKW_Benzin = 'PKW_Benzin',
  PKW_PLUGIN = 'PKW_PLUGIN',
  PKW_ELECTRO = 'PKW_ELECTRO',
  VAN = 'VAN',
  DAV_BUS = 'DAV_BUS',
  NO_MOTOR = 'NO_MOTOR',
  NEAR_PUBLIC = 'NEAR_PUBLIC',
  LONG_PUBLIC = 'LONG_PUBLIC',
  BUS = 'BUS',
  AIRPLANE = 'AIRPLANE',
}

export enum AccomodationTyp {
  BIWAK = 'BIWAK',
  CAMPING = 'CAMPING',
  HUTT = 'HUTT',
  DAV_HUTT = 'DAV_HUTT',
  HOSTEL = 'HOSTEL',
  HOTEL = 'HOTEL',
  HOTEL_LUXURY = 'HOTEL_LUXURY',
}

export enum DepartmentType {
  CLIMBING = 'CLIMBING',
  MOUNTAINEERING = 'MOUNTAINEERING',
  MOUNTAINBIKE = 'MOUNTAINBIKE',
  SKIING = 'SKIING',
  TRIATHLON = 'TRIATHLON',
  SENIOR = 'SENIOR',
  OTHER = 'OTHER',
}

export enum SportType {
  WALKING = 'WALKING',
  CLIMBING = 'CLIMBING',
  MOUNTAINCLIMBING = 'MOUNTAINCLIMBING',
  MOUNTAINEERING = 'MOUNTAINEERING',
  BOULDERING = 'BOULDERING',
  FREERIDE = 'FREERIDE',
  EXPEDITION = 'EXPEDITION',
  CAVECLIMBING = 'CAVECLIMBING',
  VIAFERRATA = 'VIAFERRATA',
  PERFORMANCEMOUNTAINEERING = 'PERFORMANCEMOUNTAINEERING',
  MTB = 'MTB',
  SNOWSHOES = 'SNOWSHOES',
  GLIDING = 'GLIDING',
  GYMNASTICS = 'GYMNASTICS',
  CROSSCOUNTRYSKI = 'CROSSCOUNTRYSKI',
  SKITOUR = 'SKITOUR',
  KAJAK = 'KAJAK',
  OTHER = 'OTHER',
}
