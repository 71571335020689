<div *transloco="let t">
  <h1 mat-dialog-title>{{ climateEntry.sTitle }}</h1>
  <div class="flex flex-wrap max-w-l min-w-[30rem]" mat-dialog-content>
    @if (climateEntry.transportations.length > 0) {
      <div class="flex flex-wrap">
        <div class="w-full">Mobilität</div>
        <hr class="h-px my-4 bg-gray-800 border-0 w-full" />
        <div class="w-1/2">Typ</div>
        <div class="w-1/4">Strecke</div>
        <div class="w-1/$">CO2</div>
        <hr class="h-px my-2 bg-gray-800 border-0 w-full" />
        @for (
          transportation of climateEntry.transportations;
          track trackByIndex($index)
        ) {
          <div class="w-1/2">
            {{ t("transportationTypes." + transportation.eType) }}
          </div>
          <div class="w-1/4">
            {{ transportation.iNumber }} x {{ transportation.iDistance }} km
          </div>
          <div class="w-1/$">
            {{
              transportation | climateCalculationTransportation | async | number
            }}
            kg
          </div>
        }
      </div>
    }

    @if (climateEntry.accommodations.length > 0) {
      <div class="flex flex-wrap">
        <div class="w-full">Mobilität</div>
        <hr class="h-px my-4 bg-gray-800 border-0 w-full" />
        <div class="w-1/2">Unterkunft</div>
        <div class="w-1/4">Personen x Nächte</div>
        <div class="w-1/$">CO2</div>
        <hr class="h-px my-2 bg-gray-800 border-0 w-full" />
        @for (
          accommodation of climateEntry.accommodations;
          track trackByIndex($index)
        ) {
          <div class="w-1/2">
            {{ t("accommodationTypes." + accommodation.eType) }}
          </div>
          <div class="w-1/4">
            {{ accommodation.iPersons }} x {{ accommodation.iNights }}
          </div>
          <div class="w-1/$">
            {{
              accommodation | climateCalculationAccommodation | async | number
            }}
            kg
          </div>
        }
      </div>
    }
  </div>
</div>
