import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import {
  ClimateEntriesStateModel,
  initializeClimateEntryStateModel,
} from './climate-entries.state.model';
import { inject, Injectable } from '@angular/core';
import { immutableContext } from '../state.helpers';

import { ClimateEntry } from '../../core/swagger/models/climate-entry';
import {
  AddClimateEntry,
  FetchClimateCalculationKeys,
  FetchBalanceEntries,
  RemoveClimateEntry,
  UpdateMultiplier,
  UpdateActiveYear,
} from './climate-entries.actions';
import { ApiService } from '../../core/swagger/services/api.service';
import { firstValueFrom } from 'rxjs';
import {
  ClimateCalculationService,
  CO2Balance,
} from '../../shared/services/climate-calculation.service';
import { ClimateCalculationKeys } from '../../core/swagger/models/climate-calculation-keys';
import { BalanceEntries } from '../../core/swagger/models/balance-entries';
import { BuildingDto } from '../../core/swagger/models/building-dto';

export const CLIMATE_ENTRY_STATE_TOKEN: StateToken<ClimateEntriesStateModel> =
  new StateToken('climateEntry');

@State<ClimateEntriesStateModel>({
  name: CLIMATE_ENTRY_STATE_TOKEN,
  defaults: initializeClimateEntryStateModel(),
})
@Injectable()
export class ClimateEntriesState {
  private readonly apiService: ApiService = inject(ApiService);

  @Selector([CLIMATE_ENTRY_STATE_TOKEN])
  public static getClimateEntries(
    state: ClimateEntriesStateModel,
  ): Array<ClimateEntry> {
    return state.climateEntries;
  }

  @Selector([CLIMATE_ENTRY_STATE_TOKEN])
  public static getBuildings(
    state: ClimateEntriesStateModel,
  ): Array<BuildingDto> {
    return state.buildings;
  }

  @Selector([CLIMATE_ENTRY_STATE_TOKEN])
  public static getClimateBalance(state: ClimateEntriesStateModel): CO2Balance {
    return ClimateCalculationService.calculateBalance(
      state.climateEntries,
      state.buildings,
      state.climateCalculationKeys!,
    );
  }

  @Selector([CLIMATE_ENTRY_STATE_TOKEN])
  public static getClimateCalculationKeys(
    state: ClimateEntriesStateModel,
  ): ClimateCalculationKeys | undefined {
    return state.climateCalculationKeys;
  }

  @Action(FetchBalanceEntries)
  public async fetchBalanceEntries({
    setState,
    getState,
  }: StateContext<ClimateEntriesStateModel>): Promise<void> {
    const balanceEntries: BalanceEntries = await firstValueFrom(
      this.apiService.adminBalanceEntriesYearGet({
        year: getState().activeYear,
      }),
    );
    setState(
      immutableContext((draftstate) => {
        draftstate.climateEntries = balanceEntries.climateEntries;
        draftstate.buildings = balanceEntries.buildings;
      }),
    );
  }

  @Action(UpdateActiveYear)
  public async updateActiveYear(
    stateContext: StateContext<ClimateEntriesStateModel>,
    { activeYear }: UpdateActiveYear,
  ): Promise<void> {
    if (stateContext.getState().activeYear !== activeYear) {
      stateContext.setState(
        immutableContext((draftstate) => (draftstate.activeYear = activeYear)),
      );
      await this.fetchBalanceEntries(stateContext);
    }
  }

  @Action(FetchClimateCalculationKeys)
  public async fetchClimateCalculationKeys({
    setState,
    getState,
  }: StateContext<ClimateEntriesStateModel>): Promise<void> {
    if (!getState().climateCalculationKeys) {
      const climateCalculationKeys: ClimateCalculationKeys =
        await firstValueFrom(this.apiService.climateCalculationKeysGet());
      setState(
        immutableContext(
          (draftstate) =>
            (draftstate.climateCalculationKeys = climateCalculationKeys),
        ),
      );
    }
  }

  @Action(AddClimateEntry)
  public addClimateEntry(
    { setState }: StateContext<ClimateEntriesStateModel>,
    { climateEntry }: AddClimateEntry,
  ): void {
    setState(
      immutableContext((draftState) =>
        draftState.climateEntries.push(climateEntry),
      ),
    );
  }

  @Action(RemoveClimateEntry)
  public removeClimateEntry(
    { setState }: StateContext<ClimateEntriesStateModel>,
    { sToken }: RemoveClimateEntry,
  ): void {
    setState(
      immutableContext(
        (draftState) =>
          (draftState.climateEntries = draftState.climateEntries.filter(
            (entry) => entry.sToken !== sToken,
          )),
      ),
    );
  }

  @Action(UpdateMultiplier)
  public updateMultiplier(
    { setState }: StateContext<ClimateEntriesStateModel>,
    { sToken, multiplier }: UpdateMultiplier,
  ): void {
    setState(
      immutableContext((draftState) =>
        draftState.climateEntries.forEach((_climateEntry, index) => {
          if (sToken === _climateEntry.sToken) {
            draftState.climateEntries[index].multiplier = multiplier;
          }
        }),
      ),
    );
  }
}
